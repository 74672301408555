import { Add, Close, InfoRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Input,
  Tooltip,
  Typography,
  autocompleteClasses,
  checkboxClasses,
  inputClasses,
  selectClasses,
} from '@mui/joy';
import { Timestamp } from 'firebase/firestore';

import { FullScreenModal } from '@components/FullScreenModal';
import { Tag } from '@features/Contacts/ContactDetail/ViewContact/ContactTag';
import { DateTimeInput } from '@features/Schedule/DesktopSchedule/EditEventModal/DateTimeInput';
import { Field } from '@features/Schedule/DesktopSchedule/EditEventModal/Field';
import { FieldGroup } from '@features/Schedule/DesktopSchedule/EditEventModal/FieldGroup';
import { InstrumentsModal } from '@features/Settings2/ServiceSettings/InstrumentsModal';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';
import { EditAddress } from './EditAddress';
import { EditContactAvatar } from './EditContactAvatar';
import { InstrumentTag } from './InstrumentTag';

const inputHeight = '39px';

export const EditContactModal = () => {
  const {
    editContactModalOpen,
    setEditContactModalOpen,
    selectedContactId,
    inMemoryContact,
    setInMemoryContact,
    onSubmit,
    isValid,
    instruments: {
      availableInstruments,
      selectedInstruments,
      setSelectedInstruments,
      showInstrumentsModal,
      setShowInstrumentsModal,
    },
  } = useContactsProvider();

  return (
    <FullScreenModal
      open={editContactModalOpen}
      onClose={() => setEditContactModalOpen(false)}
      title={selectedContactId ? 'Update Contact' : 'Create Contact'}
      testId="edit-contact-modal"
      right={
        <>
          <Button disabled={!isValid} onClick={onSubmit}>
            Save
          </Button>
        </>
      }
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={(theme) => ({
            flex: 1,
            maxWidth: '650px',
            margin: 'auto',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            paddingBottom: '500px',

            [`& .${inputClasses.root}`]: {
              minHeight: inputHeight,
            },
            [`& .${inputClasses.root}, & .${autocompleteClasses.root}`]: {
              minHeight: inputHeight,
            },
            [`& .${selectClasses.root}`]: {
              backgroundColor: 'white',
              minHeight: inputHeight,
            },
            '.tag-root': {
              ...theme.typography['body-md'],
              color: undefined,
              background: undefined,
            },
          })}
        >
          {inMemoryContact ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <EditContactAvatar />

              <FieldGroup>
                <Field>
                  <Typography level="title-md">First</Typography>
                  <Input
                    placeholder="First name"
                    value={inMemoryContact.firstName}
                    onChange={(event) =>
                      setInMemoryContact({
                        ...inMemoryContact,
                        firstName: event.target.value,
                      })
                    }
                  />
                </Field>

                <Field>
                  <Typography level="title-md">Last</Typography>
                  <Input
                    placeholder="Last name"
                    value={inMemoryContact.lastName}
                    onChange={(event) =>
                      setInMemoryContact({
                        ...inMemoryContact,
                        lastName: event.target.value,
                      })
                    }
                  />
                </Field>

                <Field
                  className="fit-content"
                  sx={{
                    flex: 0,
                    flexShrink: 0,
                    flexBasis: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography level="title-md">Birthday</Typography>

                  <DateTimeInput
                    hideTime
                    value={inMemoryContact.birthday?.toDate()}
                    onChange={(next) =>
                      setInMemoryContact({
                        ...inMemoryContact,
                        birthday: next ? Timestamp.fromDate(next) : null,
                      })
                    }
                    sx={{ width: '125px', marginTop: 'auto' }}
                  />
                </Field>
              </FieldGroup>

              <FieldGroup>
                <Field>
                  <Typography level="title-md">Email</Typography>
                  <Input
                    placeholder="Email"
                    value={
                      inMemoryContact.emails?.find((email) => email.isPrimary)
                        ?.address
                    }
                    onChange={(event) =>
                      setInMemoryContact({
                        ...inMemoryContact,
                        emails: (inMemoryContact.emails || []).map((email) =>
                          email.isPrimary
                            ? { ...email, address: event.target.value }
                            : email
                        ),
                      })
                    }
                  />
                </Field>

                <Field>
                  <Typography level="title-md">Phone</Typography>
                  <Input
                    placeholder="Phone"
                    value={
                      inMemoryContact.phones?.find((phone) => phone.isPrimary)
                        ?.number
                    }
                    onChange={(event) =>
                      setInMemoryContact({
                        ...inMemoryContact,
                        phones: (inMemoryContact.phones || []).map((phone) =>
                          phone.isPrimary
                            ? { ...phone, number: event.target.value }
                            : phone
                        ),
                      })
                    }
                  />
                </Field>
              </FieldGroup>

              <Typography
                level="title-lg"
                sx={{ marginTop: '10px' }}
                endDecorator={
                  <Tooltip
                    sx={{ color: 'black' }}
                    title="The primary contact will recieve all account notifications and is responsible for payment."
                  >
                    <InfoRounded sx={{ color: 'gray', fontSize: '18px' }} />
                  </Tooltip>
                }
              >
                Primary contact
              </Typography>

              <Box>
                <Checkbox
                  sx={(theme) => ({
                    [`& .${checkboxClasses.label}`]: {
                      ...theme.typography['body-md'],
                    },
                  })}
                  checked
                  label={`Same as contact ${
                    inMemoryContact.firstName || inMemoryContact.firstName
                      ? ` (the primary contact will be ${[
                          inMemoryContact.firstName,
                          inMemoryContact.lastName,
                        ]
                          .filter(Boolean)
                          .join(' ')})`
                      : ' (this contact will be the primary contact)'
                  }`}
                />
              </Box>

              <Field>
                <Typography level="title-md">Instruments</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '14px',
                    flexWrap: 'wrap',
                    overflowX: 'auto',
                  }}
                >
                  {availableInstruments.map((instrument) => (
                    <InstrumentTag
                      key={instrument}
                      checked={selectedInstruments.includes(instrument)}
                      onClick={() =>
                        setSelectedInstruments((current) =>
                          current.includes(instrument)
                            ? current.filter((item) => item !== instrument)
                            : [...current, instrument]
                        )
                      }
                    >
                      {instrument}
                    </InstrumentTag>
                  ))}
                  <Tag
                    sx={{
                      background: 'white',
                      border: '1px solid white',
                      cursor: 'pointer',
                      padding: '3px 0',
                    }}
                    onClick={() => setShowInstrumentsModal(true)}
                  >
                    <Add sx={{ fontSize: '16px' }} />
                    Instrument
                  </Tag>
                  <InstrumentsModal
                    isOpen={showInstrumentsModal}
                    onClose={() => setShowInstrumentsModal(false)}
                    selectedInstruments={selectedInstruments}
                    setSelectedInstruments={setSelectedInstruments}
                  />
                </Box>
              </Field>

              <Field>
                <Typography level="title-md">Tags</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '14px',
                    flexWrap: 'wrap',
                    overflowX: 'auto',
                  }}
                >
                  {['Parent', 'Primary financial'].map((tagText, tagIndex) => (
                    <Tag
                      key={tagIndex}
                      sx={{
                        border: '1px solid black',
                        background: 'white',
                        padding: '3px 10px',
                      }}
                    >
                      <Close sx={{ fontSize: '16px' }} /> {tagText}
                    </Tag>
                  ))}{' '}
                  <Tag
                    sx={{
                      background: 'white',
                      border: '1px solid white',
                      padding: '3px 0',
                    }}
                  >
                    <Add sx={{ fontSize: '16px' }} />
                    Tag
                  </Tag>
                </Box>
              </Field>

              <EditAddress />
            </Box>
          ) : null}
        </Box>
      </Box>
    </FullScreenModal>
  );
};
