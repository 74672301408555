import {
  avatarClasses,
  boxClasses,
  buttonClasses,
  checkboxClasses,
  inputClasses,
  listDividerClasses,
  listItemButtonClasses,
  listItemClasses,
  listItemDecoratorClasses,
  sheetClasses,
  svgIconClasses,
} from '@mui/joy';
import { PaletteRange, extendTheme } from '@mui/joy/styles';
import { formControlLabelClasses } from '@mui/material';

export const themeColors = {
  blue2: '#8fb8ca',
  lavender: '#dfcddf',
  blush: '#ffeae5',
  yellow: '#c77f00',
  lightYellow: '#fef0d7',
  darkYellow: '#d1a058',
  darkRed: '#df6b6c',
  lightRed: '#fee3e1',
  darkPurple: '#9180db',
  lightBlue: '#effafd',
  darkBlue: '#71baff',
  darkerBlue: 'rgb(31 146 255)',
  lightGreen: '#bbedd7',
  darkGreen: '#009a72',
  lightGray: '#f5f5f5',
  darkGray: '#e0e0e0',
  lightPeach: '#ffeae5',
  veryLightPink: '#fff5f5',
  rgb: {
    darkBlue: [113, 186, 255] as [number, number, number],
    darkYellow: [209, 160, 88] as [number, number, number],
    yellow: [199, 127, 0] as [number, number, number],
    blue2: [143, 184, 202] as [number, number, number],
    lavender: [223, 205, 223] as [number, number, number],
    darkPurple: [145, 128, 219] as [number, number, number],
  },
  warning: '#dd1c00',
};

export const inputHeight = '40px';

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    gray: true;
  }

  interface Palette {
    gray: PaletteRange;
  }
}
declare module '@mui/joy/Select' {
  interface SelectPropsVariantOverrides {
    tag: true;
  }
}
declare module '@mui/joy/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
  }
}
declare module '@mui/joy/Input' {
  interface InputPropsVariantOverrides {
    search: true;
  }
}
declare module '@mui/joy/Button' {
  interface ButtonPropsVariantOverrides {
    verticalBasic: true;
    verticalPlain: true;
    verticalOutline: true;
    verticalSolid: true;
    instrument: true;
    roundedButton: true;
  }
}

declare module '@mui/joy/List' {
  interface ListPropsVariantOverrides {
    centered: true;
    contactList: true;
  }
}

declare module '@mui/joy/Badge' {
  interface BadgePropsSizeOverrides {
    xs: true;
  }
}

export const transition = '0.18s';
const verticalButtonSize = {
  sm: 50,
  md: 60,
  lg: 70,
};

const isVerticalButton = (variant: string | undefined) =>
  variant === 'verticalBasic' ||
  variant === 'verticalPlain' ||
  variant === 'verticalOutline' ||
  variant === 'verticalSolid' ||
  variant === 'instrument';

const isVerticalCircleIconButton = (variant: string | undefined) =>
  variant === 'verticalPlain' ||
  variant === 'verticalOutline' ||
  variant === 'verticalSolid' ||
  variant === 'instrument';

export default extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          '50': '#e6f0ff',
          '100': '#c3d8ff',
          '200': '#9fc1fe',
          '300': '#7fa8fc',
          '400': '#6f96fa',
          '500': '#448bff',
          '600': '#6775e3',
          '700': '#6263ce',
          '800': '#5d51ba',
          '900': '#543097',
          mainChannel: '68 139 255', // 500
          lightChannel: '195 216 255',
          darkChannel: '103 117 227',
          solidBg: 'var(--joy-palette-primary-500)',
        },
        neutral: {
          '50': '#f5f5f5',
          '100': '#e9e9e9',
          '200': '#d9d9d9',
          '300': '#c4c4c4',
          '400': '#9d9d9d',
          '500': '#7b7b7b',
          '600': '#555555',
          '700': '#434343',
          '800': '#262626',
          '900': '#000000',
          mainChannel: '0 0 0', // 900
          lightChannel: '196 196 196',
          darkChannel: '67 67 67',
          softBg: 'var(--joy-palette-neutral-50)',
          solidBg: 'var(--joy-palette-neutral-900)',
          plainHoverBg: 'var(--joy-palette-neutral-50)',
          outlinedColor: 'var(--joy-palette-neutral-900)',
          outlinedHoverBg: 'var(--joy-palette-neutral-50)',
          plainActiveBg: 'var(--joy-palette-neutral-50)',
        },
        gray: {
          '50': '#f5f5f5',
          '100': '#e9e9e9',
          '200': '#d9d9d9',
          '300': '#c4c4c4',
          '400': '#757575',
          '500': '#7b7b7b',
          '600': '#555555',
          '700': '#434343',
          '800': '#262626',
          '900': '#000000',
          mainChannel: '117 117 117',
          lightChannel: '196 196 196',
          darkChannel: '67 67 67',
          softBg: 'var(--joy-palette-gray-50)',
          solidBg: 'var(--joy-palette-gray-900)',
          plainHoverBg: 'var(--joy-palette-gray-50)',
          outlinedColor: 'var(--joy-palette-gray-900)',
          outlinedHoverBg: 'var(--joy-palette-gray-50)',
          plainActiveBg: 'var(--joy-palette-gray-50)',
        },
        background: {
          body: 'var(--joy-palette-neutral-50)',
        },
      },
    },
    dark: {
      palette: {
        // primary: {
        //   '50': '#e6f0ff',
        //   '100': '#c3d8ff',
        //   '200': '#9fc1fe',
        //   '300': '#7fa8fc',
        //   '400': '#6f96fa',
        //   '500': '#6a84f3',
        //   '600': '#6775e3',
        //   '700': '#6263ce',
        //   '800': '#5d51ba',
        //   '900': '#543097',
        //   mainChannel: '106 132 243', // 500
        //   lightChannel: '195 216 255',
        //   darkChannel: '103 117 227',
        //   solidBg: 'var(--joy-palette-primary-500)',
        // },
        neutral: {
          //   '50': '#f5f5f5',
          //   '100': '#e9e9e9',
          //   '200': '#d9d9d9',
          //   '300': '#c4c4c4',
          //   '400': '#9d9d9d',
          //   '500': '#7b7b7b',
          //   '600': '#555555',
          //   '700': '#434343',
          //   '800': '#262626',
          //   '900': '#000000',
          //   mainChannel: '0 0 0', // 900
          //   lightChannel: '196 196 196',
          //   darkChannel: '67 67 67',
          //   solidBg: 'var(--joy-palette-neutral-900)',
          //   plainHoverBg: 'var(--joy-palette-neutral-50)',
          //   outlinedHoverBg: 'var(--joy-palette-neutral-50)',
          //
          // --joy-palette-neutral-plainActiveBg
          // plainActiveBg: 'red',
          // plainActiveBg: 'red',
          plainActiveBg: 'var(--joy-palette-neutral-700)',
        },
        background: {
          body: 'var(--joy-palette-common-black)',
          surface: 'var(--joy-palette-neutral-900)',
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: 35,
      fontFamily: '"Times New Roman", Times, serif',
      letterSpacing: '0.2px',
      fontWeight: 400,

      '@media (min-width: 747px)': {
        fontSize: 70,
      },
    },
    h2: {
      fontSize: '24px',
    },
    h3: {
      fontSize: '20px',
    },
    h4: {
      fontSize: '18px',
    },
    'title-lg': {
      fontWeight: 400,
      fontSize: '16px',
    },
    'title-md': {
      fontSize: '14px',
      fontWeight: 600,
    },
    'body-md': {
      fontSize: '14px',
    },
    'body-sm': {
      fontSize: '13px',
    },
    'body-xs': {
      fontSize: '12px',
    },
  },
  components: {
    JoyBadge: {
      styleOverrides: {
        badge: ({ ownerState }) => ({
          ...(ownerState.size === 'xs' && {
            borderRadius: '50%',
            padding: '1px',
            '& .MuiSvgIcon-root': {
              height: '1em',
              width: '1em',
              fontSize: '12px',
            },
          }),
        }),
      },
    },
    JoyTypography: {
      defaultProps: {
        color: 'neutral',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'label' && {
            fontSize: '12px',
            fontWeight: 600,
            textTransform: 'uppercase',
            color: 'gray',
          }),
        }),
      },
    },
    JoyModalDialog: {
      styleOverrides: {
        root: {
          '@media (min-width: 747px)': {
            height: '70vh',
            minHeight: 200,
            width: '80vw',
            maxWidth: '900px',
          },

          '@media (max-width: 746px)': {
            height: '100vh',
            width: '100%',
            maxHeight: 'unset',
            maxWidth: 'unset',
          },
        },
      },
    },
    JoyMenu: {
      styleOverrides: {
        root: {
          '--joy-palette-neutral-plainHoverBg':
            'var(--joy-palette-neutral-200)',
        },
      },
    },
    JoyModalClose: {
      styleOverrides: {
        root: {
          top: 11,
          right: 11,
        },
      },
    },
    JoyCheckbox: {
      styleOverrides: {
        root: {
          [`& .${formControlLabelClasses.label}`]: {
            marginLeft: 8,
          },
          [`& .${svgIconClasses.root}`]: {
            fontSize: 18,
          },
        },
        checkbox: {
          '--joy-palette-background-surface': 'transparent',
          transition: `${transition} background-color`,
          borderRadius: '50%',
        },
      },
    },
    JoySelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'tag' && {
            padding: '2px 11px',
            borderRadius: '50px',
            minHeight: 0,
            border: '1px solid var(--joy-palette-neutral-500)',
            background: 'white',
          }),
        }),
        indicator: ({ ownerState }) => ({
          ...(ownerState.variant === 'tag' && {
            marginLeft: 0,
            marginRight: '-4px',
            marginBottom: '-1px',

            '& svg': {
              fontSize: '19px',
            },
          }),
        }),
      },
    },
    JoyButton: {
      defaultProps: {
        color: 'neutral',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const color = theme.palette[ownerState.color || 'primary'];

          return {
            '& svg': {
              color: 'currentColor',
            },
            transition: `${transition} background-color`,
            ...(ownerState.size === 'lg' && {
              minHeight: '52px',
            }),
            ...(ownerState.variant === 'plain' && {
              backgroundColor: 'transparent',
              ...(ownerState.color === 'neutral' && {
                color: theme.palette.neutral[500],
              }),
              transition: `${transition} color`,
              '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.common.black,
              },
            }),

            [`& .${buttonClasses.endDecorator} .${svgIconClasses.root}`]: {
              transition: 'transform 0.3s ease-out',
              transform: 'translateX(-8px)',
              fontSize: '15px',
              marginTop: '2px',
            },
            '&:hover': {
              ...((ownerState.variant === 'outlined' ||
                ownerState.variant === 'plain') && {
                backgroundColor: 'transparent',
              }),
              [`& .${buttonClasses.endDecorator} .${svgIconClasses.root}`]: {
                transform: 'translateX(-3px)',
              },
            },

            ...(ownerState.color === 'neutral' && {
              borderColor: 'currentColor',
            }),
            ...(ownerState.variant === 'roundedButton' && {
              flexDirection: 'column',
              fontSize: 10,
              background: 'transparent',
              padding: '5px 10px',
              borderRadius: '0.5em',
              textAlign: 'center',
              height: 50,
              width: 50,
              '& svg': {
                fontSize: '18px',
              },

              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',

                [theme.getColorSchemeSelector('dark')]: {
                  backgroundColor: 'rgba(255, 255, 255, 0.15)',
                },
              },
            }),
            ...(isVerticalButton(ownerState.variant) && {
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBlock: 0,
              paddingInline: 0,
              fontWeight: 400,
            }),
            ...(isVerticalCircleIconButton(ownerState.variant) && {
              [`& .${avatarClasses.root}`]: {
                transition: `${transition} opacity`,
              },
              '&:hover': {
                [`& .${buttonClasses.startDecorator}`]: {
                  [`& .${avatarClasses.root}`]: {
                    opacity: 0.8,
                  },

                  ...(ownerState.variant === 'verticalOutline' && {
                    backgroundColor: theme.palette.common.black,
                    color: theme.palette.common.white,

                    [theme.getColorSchemeSelector('dark')]: {
                      backgroundColor: '#b0b0b0',
                      color: theme.palette.common.black,
                    },
                  }),
                  ...(ownerState.variant === 'verticalSolid' && {
                    backgroundColor: `rgb(${color.darkChannel})`,
                  }),
                },
              },
              // TODO: we might not want this everywhere
              '&:active': {
                [`& .${buttonClasses.startDecorator}`]: {
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                },
              },
            }),

            ...(ownerState.variant === 'instrument' && {
              position: 'relative',
              paddingBottom: '34px',
              [theme.getColorSchemeSelector('dark')]: {
                filter: 'invert(1)',
                color: theme.palette.common.black,
              },
              '&:hover': {
                backgroundColor: theme.palette.neutral[50],
                [theme.getColorSchemeSelector('dark')]: {
                  backgroundColor: 'rgb(0 0 0 / 10%)',
                },
              },
              '&:active': {
                backgroundColor: theme.palette.neutral[100],
                [theme.getColorSchemeSelector('dark')]: {
                  backgroundColor: 'rgb(0 0 0 / 15%)',
                },
                [`& .${buttonClasses.startDecorator}`]: {
                  backgroundColor: 'transparent',
                },
                [`& .${checkboxClasses.checkbox}.${checkboxClasses.checked}`]: {
                  backgroundColor:
                    'var(--variant-solidActiveBg, var(--joy-palette-primary-solidActiveBg, var(--joy-palette-primary-700, #12467B))) !important',
                },
              },
              [`& .${buttonClasses.startDecorator}`]: {
                height: `${verticalButtonSize[ownerState.size || 'md']}px`,
                width: `${verticalButtonSize[ownerState.size || 'md'] + 20}px`,
                transition: `${transition} background-color`,
                [`& .${checkboxClasses.root}`]: {
                  [theme.getColorSchemeSelector('dark')]: {
                    filter: 'invert(1)',
                  },
                  margin: '130px 0 0 0',
                  '--joy-palette-neutral-outlinedHoverBg': 'transparent',
                },
                [`& .${checkboxClasses.checkbox}.${checkboxClasses.checked}`]: {
                  transition: `${transition} background-color`,
                  '&:hover': {
                    backgroundColor:
                      'var(--variant-solidBg, var(--joy-palette-primary-solidBg, var(--joy-palette-primary-500)))',
                  },
                },
                '& img': {
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  margin: 'auto',
                  top: '8px',
                  height: 45,
                  width: 45,
                },
              },
            }),
          };
        },
        startDecorator: ({ ownerState, theme }) => ({
          ...(isVerticalButton(ownerState.variant) && {
            marginRight: 0,
            marginTop: '4px',
            '& svg': { margin: 'auto' },
          }),
          ...(isVerticalCircleIconButton(ownerState.variant) && {
            borderColor: theme.palette.common.black,
            borderRadius: '50%',
            height: verticalButtonSize[ownerState.size || 'md'],
            width: verticalButtonSize[ownerState.size || 'md'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: `${transition} color, 0.3s background-color`,

            [`& .${boxClasses.root}`]: {
              height: 'fit-content',
            },
          }),
          ...(ownerState.variant === 'verticalOutline' && {
            border: '1px solid',
          }),
          ...(ownerState.variant === 'verticalSolid' && {
            color: theme.palette.common.white,
            backgroundColor: `rgb(${
              theme.palette[ownerState.color || 'primary'].mainChannel
            })`,
          }),
        }),
      },
    },
    JoyAutocomplete: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '--Input-minHeight': inputHeight,
          '--Input-focusedThickness': 0,
          ...(ownerState.variant === 'outlined' && {
            boxShadow: 'none',
            '--joy-palette-background-surface': 'transparent',
            '--Input-minHeight': '56px',
            '--Input-radius': '6px',
            transition: `${transition} border-color`,
            [`&:hover, &.${inputClasses.focused}`]: {
              borderColor: 'var(--joy-palette-neutral-900)',
            },
          }),
        }),
      },
    },
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '--Input-minHeight': inputHeight,
          '--Input-focusedThickness': 0,
          ...(ownerState.variant === 'plain' && {
            backgroundColor: 'transparent',
            borderRadius: 0,
            paddingLeft: '2px',
          }),
          ...(ownerState.variant === 'outlined' && {
            boxShadow: 'none',
            '--joy-palette-background-surface': 'transparent',
            '--Input-minHeight': '56px',
            '--Input-radius': '6px',
            transition: `${transition} border-color`,
            [`&:hover, &.${inputClasses.focused}`]: {
              borderColor: 'var(--joy-palette-neutral-900)',
            },
          }),
          ...(ownerState.variant === 'search' && {
            backgroundColor: theme.palette.background.level1,
            border: 'none',
            outline: 'none',
            minHeight: '36px',
            '&:hover:not(.Mui-focused)': {
              opacity: 0.7,
              [theme.getColorSchemeSelector('dark')]: {
                opacity: 0.9,
              },
            },
            '&:focus': {
              opacity: 1,
            },
            '&:before': {
              boxShadow: 'none',
            },
          }),
        }),
        startDecorator: ({ ownerState }) => ({
          ...(ownerState.variant === 'search' && {
            [`& .${svgIconClasses.root}`]: {
              color: 'rgb(102, 102, 102)',
            },
          }),
        }),
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: {
          '&:active, &:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    JoyTooltip: {
      styleOverrides: {
        root: {
          maxWidth: '300px',
          backgroundColor: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
        },
      },
    },
    JoyList: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...((ownerState.variant === 'centered' ||
            ownerState.variant === 'contactList') && {
            display: 'block',
            flex: 1,
            [`& .${listDividerClasses.root}`]: {
              margin: 0,
            },
            [`& .${listItemButtonClasses.root}`]: {
              paddingTop: ownerState.variant === 'contactList' ? '15px' : '6px',
              paddingBottom:
                ownerState.variant === 'contactList' ? '15px' : '6px',
              paddingLeft: '15px',
              paddingRight: '15px',
              marginRight: 0,
              marginLeft: 0,
            },
            [`& .${listItemDecoratorClasses.root}`]: {
              marginRight: '15px',
            },
            [`& .${listItemClasses.root}`]: {
              padding: '4px 0',
              '--ListItem-minHeight': 0,
            },
            [`& .${sheetClasses.root}`]: {
              padding: 0.5,
              lineHeight: 0,
              borderRadius: 'sm',
            },
          }),
        }),
      },
    },
  },
});
