import {
  calendarOutline,
  calendarSharp,
  cardOutline,
  cardSharp,
  chatbubbleEllipses,
  chatbubbleEllipsesOutline,
  chatbubbleEllipsesSharp,
  homeOutline,
  homeSharp,
  peopleOutline,
  peopleSharp,
  playCircleOutline,
  playCircleSharp,
  time,
} from 'ionicons/icons';
import { lazy, useMemo } from 'react';

import { useFeatureFlag } from '@components/FeatureFlags/useFeatureFlag';
import ContactsList from '@features/Contacts2/ContactsList';
import {
  useOrgainzation,
  useOrganizationPermissions,
} from '@features/Organization/organizationSlice';

const Home = lazy(() => import('@features/Home'));
const Lessons = lazy(() => import('@features/Lessons'));
const Messages = lazy(() => import('@features/Messages'));
const Schedule = lazy(() => import('@features/Schedule'));
const ContactGroup = lazy(() => import('@features/Contacts/ContactGroup'));
const Payments = lazy(() => import('@features/Payments'));

const homePaths = ['/:organizationSlug/home'];
const tabs = [
  {
    title: 'Home',
    paths: homePaths,
    selected: homePaths,
    url: '/home',
    Component: Home,
    iosIcon: homeOutline,
    mdIcon: homeSharp,
    desktopIosIcon: homeOutline,
    desktopMdIcon: homeSharp,
    exact: false,
    hotkeys: { first: 'G', second: 'H' },
  },
  {
    title: 'Schedule',
    url: '/schedule',
    Component: Schedule,
    iosIcon: time,
    mdIcon: calendarSharp,
    desktopIosIcon: calendarOutline,
    desktopMdIcon: calendarSharp,
  },
  {
    title: 'Messages',
    url: '/messages',
    Component: Messages,
    iosIcon: chatbubbleEllipses,
    mdIcon: chatbubbleEllipsesSharp,
    desktopIosIcon: chatbubbleEllipsesOutline,
    desktopMdIcon: chatbubbleEllipsesSharp,
  },
  {
    title: 'Contacts',
    url: '/contacts',
    Component: ContactGroup,
    selected: [
      '/:organizationId/send-invite/:inviteId',
      '/:organizationId/lesson-notes',
    ],
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
    desktopIosIcon: peopleOutline,
    desktopMdIcon: peopleSharp,
    hotkeys: { first: 'G', second: 'C' },
  },
  {
    title: 'Contacts (beta)',
    url: '/contacts2',
    Component: ContactsList,
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
    desktopIosIcon: peopleOutline,
    desktopMdIcon: peopleSharp,
    hotkeys: { first: 'G', second: 'C' },
  },
  {
    title: 'Practice',
    url: '/lessons',
    Component: Lessons,
    iosIcon: playCircleOutline,
    mdIcon: playCircleSharp,
    desktopIosIcon: playCircleOutline,
    desktopMdIcon: playCircleSharp,
  },
  {
    title: 'Payments',
    url: '/payments',
    Component: Payments,
    iosIcon: cardOutline,
    mdIcon: cardSharp,
    desktopIosIcon: cardOutline,
    desktopMdIcon: cardSharp,
    hotkeys: { first: 'G', second: 'P' },
  },
];

export const useTabs = () => {
  const organization = useOrgainzation();
  const messagingEnabled = useFeatureFlag('messaging');
  const permissions = useOrganizationPermissions();

  return useMemo(
    () =>
      tabs.filter(
        (tab) =>
          (tab.url !== '/messages' || messagingEnabled) &&
          (tab.url !== '/schedule' || organization?.plan === 'pro') &&
          (tab.url !== '/lessons' || permissions?.isTeacher === false)
      ),
    [messagingEnabled, permissions, organization]
  );
};
