import Box, { BoxProps } from '@mui/joy/Box';

export const Field = (props: BoxProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    }}
    {...props}
  />
);
